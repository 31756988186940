import { Link } from 'react-router-dom';

import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';

interface IHkBreadcrumb {
  items: BreadcrumbItemType[];
  className?: string;
}

export function Breadcrumb({ items, className }: IHkBreadcrumb) {
  const handleItemRender = (item: any) => {
    const last =
      items.findIndex((checkedItem) => checkedItem.title === item.title) ===
      items.length - 1;
    return last ? (
      <span>{item.title}</span>
    ) : (
      <Link to={item.path}>{item.title}</Link>
    );
  };

  return (
    <AntdBreadcrumb
      className={className}
      itemRender={handleItemRender}
      items={items}
      separator={'>'}
    />
  );
}
