import { SelectProps } from 'antd';

import { LawyerStatusUIConfiguration, TApi, TServices } from 'types';
import { LawyerStatus } from 'types/enums';

export const DEBOUNCE_DEFAULT_DURATION_MILLISECONDS = 600;

export const SERVICES: Record<TApi, TServices> = {
  AUTH: 'auth',
  CASE: 'case',
  DOCUMENT: 'document',
  OFFER: 'offer',
};

export const ADMIN_TYPE_OPTIONS: SelectProps['options'] = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Super Admin',
    value: 'super_admin',
  },
  {
    label: 'Marketing',
    value: 'marketing',
  },
];

export const LAWYER_STATUS_UI_CONFIGURATION: Record<
  string,
  LawyerStatusUIConfiguration
> = {
  [LawyerStatus.pending]: { color: 'yellow' },
  [LawyerStatus.in_review]: { color: 'yellow' },
  [LawyerStatus.approved]: { color: 'green' },
  [LawyerStatus.rejected]: { color: undefined },
  [LawyerStatus.totally_rejected]: { color: undefined },
  [LawyerStatus.temporary_suspended]: { color: 'orange' },
  [LawyerStatus.permanent_suspended]: { color: 'red' },
};

export const GENDER_MAP: Record<string, string> = {
  male: 'Lak-laki',
  female: 'Perempuan',
};

export const CONSULTATION_TYPE_MAP: Record<string, string> = {
  chat: 'Pesan Singkat',
};

export const GENDER_OPTIONS: SelectProps['options'] = [
  {
    label: 'Laki-laki',
    value: 'male',
  },
  {
    label: 'Perempuan',
    value: 'female',
  },
];

export const DEGREE_OPTIONS: SelectProps['options'] = [
  {
    label: 'S1',
    value: 'bachelor',
  },
  {
    label: 'S2',
    value: 'master',
  },
  {
    label: 'S3',
    value: 'doctoral',
  },
];

export const PAYMENTS_OPTIONS: SelectProps['options'] = [
  // // Virtual Account
  // { label: 'BCA Virtual Account', value: 'bca_va' },
  // { label: 'Mandiri Virtual Account', value: 'mandiri_va' },
  // { label: 'BNI Virtual Account', value: 'bni_va' },
  // { label: 'BRI Virtual Account', value: 'bri_va' },
  // { label: 'Permata Virtual Account', value: 'permata_va' },

  // // E-Wallets
  // { label: 'GoPay', value: 'gopay' },
  // { label: 'OVO', value: 'ovo' },
  // { label: 'DANA', value: 'dana' },
  // { label: 'LinkAja', value: 'linkaja' },
  // { label: 'ShopeePay', value: 'shopeepay' },

  // // Direct Debit
  // { label: 'BCA KlikPay', value: 'bca_klikpay' },
  // { label: 'Mandiri Clickpay', value: 'mandiri_clickpay' },
  // { label: 'CIMB Clicks', value: 'cimb_clicks' },

  // // Credit Cards
  // { label: 'Credit Card', value: 'credit_card' },

  // // Retail Outlets
  // { label: 'Indomaret', value: 'indomaret' },
  // { label: 'Alfamart', value: 'alfamart' },
  // { label: 'Alfamidi', value: 'alfamidi' },
  { label: 'Pembayaran Penuh', value: 'full_payment' },
];
