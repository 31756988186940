import { Route, Routes } from 'react-router-dom';

import { lazyNamedExport } from 'utils/lazy-named-export';

const ProjectBased = lazyNamedExport(
  import(/* webpackChunkName: "ProjectBased" */ 'pages/project-based'),
  'ProjectBased'
);
const ProjectBasedDetail = lazyNamedExport(
  import(
    /* webpackChunkName: "ProjectBasedDetail" */ 'pages/project-based-detail'
  ),
  'ProjectBasedDetail'
);
const ProjectBasedProposal = lazyNamedExport(
  import(
    /* webpackChunkName: "ProjectBasedProposal" */ 'pages/project-based-proposal'
  ),
  'ProjectBasedProposal'
);

export const ProjectBasedRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ProjectBased />} />
      <Route path="/:id" element={<ProjectBasedDetail />} />
      <Route path="/:id/:proposalId" element={<ProjectBasedProposal />} />
    </Routes>
  );
};
